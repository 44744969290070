
var waveform_canvas = document.querySelector('.circular-waveform canvas');

if( waveform_canvas ) {
    const canvas = waveform_canvas;
    const style = window.getComputedStyle(canvas);
    canvas.width = parseInt(style.width.split('px')[0], 10);
    canvas.height = parseInt(style.height.split('px')[0], 10);
}

    const pn = new Perlin('random seed');


    // renderWaveform(0, canvas);



  function renderWaveform(tic, canvas) { 
    setTimeout(() => {
      const volume = -900;//rand(0, -100);
      requestAnimationFrame(() => drawWaveform(tic, canvas, volume / 100));
      if(document.visibilityState == "visible") {
        renderWaveform(tic+1, canvas);
      }
    }, 30); 
  }

  function drawWaveform(tic, canvas, volume) {
    const ctx = canvas.getContext('2d');
    const w = canvas.width;
    const h = canvas.height;
    const r = 150;
    const dots = 180;
    const angleUnit = Math.PI * 2 / dots;
    let angle = 0;
    let x0, y0;

    ctx.clearRect(0, 0, w, h);

    for (let i = 0; i < dots + 1; i++) {
      const { x, y } = randCoordinate(w, h, r, angle);

      if (i !== 0) {
        const rising = i % 2 === 0 ? 1 : -1;
        const pA = plannedAmplitude(i, tic, volume);
        const { x: xC, y: yC } = randCoordinate(w, h, r + pA * rising, angle - angleUnit / 2);
        ctx.beginPath();
        ctx.moveTo(x0, y0);
        ctx.quadraticCurveTo(xC, yC, x, y);
        ctx.lineWidth = 0.5;
        //ctx.strokeStyle = `hsl(${Math.floor(angle * 360)}, 100%, 65%)`;
        //ctx.strokeStyle = `hsla(${Math.sin(-tic / 360)*360}, 100%, 55%, .5)`;
        //ctx.strokeStyle = `hsl(${300 - pA*2}, 100%, 65%)`;
        ctx.strokeStyle = `hsl(0, 0%, 100%)`;
        ctx.stroke();
      }

      angle += angleUnit;
      x0 = x, y0 = y;
    }
  }

  function randCoordinate(w, h, r, angle) {
    return {
      x: r * Math.cos(angle) + w / 2,
      y: r * Math.sin(angle) + h / 2
    };
  }

  function randAmplitude(volume) {
    return rand(10, 1) * volume;
  }

  function plannedAmplitude(i,tic,volume){
    //return ( Math.sin(i/360) + Math.tan((tic+Math.sqrt(i)) /100)*volume ); 
   
    var beat = ( Math.pow( 1 - Math.abs(Math.sin(tic/6)),16)) * 7;
    
    var beat2 = ( Math.pow( 1 - Math.abs(Math.sin(tic/12)),16)) * Math.pow( Math.abs(1-Math.sin( (i/30) )),4) / 6;
    //return beat2;
    var x = pn.noise(i/1000, 0, 0);
    var y = pn.noise(i, tic/10.12, rand(1,0)+beat );
    var z = pn.noise(-i/1.121, 0, tic/6);
    
    var q  =  Math.pow(Math.abs(Math.sin(tic/i)),40)*10;
    var q2 =  Math.pow(Math.abs(Math.sin(-tic/(tic+i))),20)*5;
    
    
    //return Math.pow(1 - Math.abs(Math.sin(tic/8)), 8)  * 10;
    return ( ( x * y * 25) - (z*1) + q +  beat + beat2 ) * volume;
    //return ( pn.noise( Math.tan(i/7) , Math.sin(tic/20),0 ) * 5 *volume );
    
  }

  function rand(max = 1, min = 0) {
    return min + (max - min) * Math.random();
  }


