// Child Theme Scripts
console.log('Child app.js'); 

/**
 * fire_scripts Event
 * the fire_scripts event is triggered on page load, and every time smoothState is finished loading a request.
 * Instead of putting code in $(document).load() like you may be accustomed, any code that you need to run everytime
 * there's fresh content on screen needs to be here:
 */
jQuery(document).on('fire_scripts', '#wrapper', function(){
    console.log('Child Theme Script');


		var waveform_canvas = document.querySelector('.circular-waveform canvas');

		if( waveform_canvas ) { 

		    const canvas = waveform_canvas;
			const style = window.getComputedStyle(canvas);
			canvas.width = parseInt(style.width.split('px')[0], 10);
			canvas.height = parseInt(style.height.split('px')[0], 10);

			const pn = new Perlin('random seed');

			if (typeof canvas !== 'undefined') {
		   		renderWaveform(0, canvas);
		   	}

		   	window.onfocus = function() {
		   		if( waveform_canvas && typeof canvas !== 'undefined' ) {
		   			renderWaveform(0, canvas);
		   		}
		   	};
 
		}

		// jQuery('.slideshow').each(function(){
		//     $timing = 8000;
		//     $slideshow = jQuery(this);
		//     setInterval(function(){
		//         $slideshow.children('img').first().appendTo( $slideshow ); 
		//     }, $timing);
		// });

				



});  